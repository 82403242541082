import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery"

const Galerija = ({ data }) => {
  console.log(data)
  const slike = data.jela.edges
  const [activeFilter, setActiveFilter] = useState("all")
  let filteredSlike = []
  if (activeFilter !== "all") {
    filteredSlike = slike.filter(
      slika => slika.node.relativeDirectory === activeFilter
    )
  } else {
    filteredSlike = slike
  }

  return (
    <Layout>
      <SEO title="Galerija" />
      <div className="content galerija-page">
        <h1>Gallery</h1>
        <div className="filters-container">
          <div
            className={activeFilter === "all" ? "filter active" : "filter"}
            onClick={() => setActiveFilter("all")}
          >
            All
          </div>
          <div
            className="filter"
            className={
              activeFilter === "galerija/jela" ? "filter active" : "filter"
            }
            onClick={() => setActiveFilter("galerija/jela")}
          >
            Food
          </div>
          <div
            className="filter"
            className={
              activeFilter === "galerija/ostalo" ? "filter active" : "filter"
            }
            onClick={() => setActiveFilter("galerija/ostalo")}
          >
            Misc
          </div>
        </div>
        <Gallery slike={filteredSlike} />
      </div>
    </Layout>
  )
}

export default Galerija

export const query = graphql`
  query {
    jela: allFile(filter: { relativeDirectory: { regex: "/galerija/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(sizes: "(min-width: 10px) 2000px, 2000px", maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
          relativeDirectory
        }
      }
    }
  }
`
