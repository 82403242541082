import React from "react"
import { useState } from "react"
import Img from "gatsby-image"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const Gallery = props => {
  const slike = props.slike
  const slikeSrc = slike.map(slika => {
    return slika.node.childImageSharp.fluid.src
  })

  const [photoIndex, setPhotoIndex] = useState(null)
  const [lightbox, setLightbox] = useState(false)

  return (
    <>
      <div className="gallery-container">
        {slike.map((slika, i) => (
          <a
            key={i}
            href={slika.node.childImageSharp.fluid.src}
            onClick={e => {
              e.preventDefault()
              setPhotoIndex(i)
              setLightbox(true)
            }}
          >
            <Img
              key={slika.node.childImageSharp.id}
              fluid={{
                ...slika.node.childImageSharp.fluid,
                sizes: "(min-width: 768px) 400px, 100vw",
                aspectRatio: 1.503759398496241,
              }}
              alt="room image"
            />
          </a>
        ))}
      </div>
      {lightbox && (
        <Lightbox
          mainSrc={slikeSrc[photoIndex]}
          nextSrc={slikeSrc[(photoIndex + 1) % slikeSrc.length]}
          prevSrc={
            slikeSrc[(photoIndex + slikeSrc.length - 1) % slikeSrc.length]
          }
          onCloseRequest={() => setLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + slikeSrc.length - 1) % slikeSrc.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % slikeSrc.length)
          }
        />
      )}
    </>
  )
}

export default Gallery
